import React from 'react'
import Link from '../components/Link'
import './nav.css'

const Nav = () => {
  return (
    <nav aria-label='Main Menu' role='navigation' className='navigation'>
      <ul>
      <li className='navItem'>
          <Link to='/'>Home</Link>
        </li>
        <li className='navItem'>
          <Link to='/work'>Our work</Link>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
