import React from 'react'
import Nav from './Nav'
import './layout.css'

const Layout = ({ children }) => (
  <main className='layout'>
    <Nav />
    {children}
  </main>
)
export default Layout
