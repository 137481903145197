import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Link = ({ to, children, ...restProps }) => {
  return (
    <AniLink
      to={to}
      {...restProps}
      cover
      direction='top'
      duration={1}
      bg='#E84545'
    >
        {children}
    </AniLink>
  )
}

export default Link